export default {
  nano: '4px',
  micro: '8px',
  extraSmall: '12px',
  xs: '16px',
  small: '24px',
  medium: '32px',
  large: '40px',
  extraLarge: '48px',
  xxLarge: '52px',
};
