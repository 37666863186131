import api from './api';

const PARTNER = '@mlabs-partner';

export const setPartnerStore = (obj) => {
  sessionStorage.setItem(PARTNER, JSON.stringify(obj));
};

export const setPartner = () => {
  clearPartnerStore();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const clientId = urlParams.get('client_id');
  const callbackUrl = urlParams.get('callback_url');
  const partnerObj = {
    client_id: clientId,
    callback_url: callbackUrl,
  };
  const hasPartner = urlParams.has('client_id') && urlParams.has('callback_url');

  return hasPartner && setPartnerStore(partnerObj);
};

export const getPartnerStore = () => sessionStorage.getItem(PARTNER);

export const clearPartnerStore = () => sessionStorage.removeItem(PARTNER);

export const getOauthPartner = async (clientId, callbackUrl) => {
  const data = await api.get(`/oauth/partner?client_id=${clientId}&callback_url=${callbackUrl}`);

  return data;
};

export const getOauthCheckAuthorization = async (clientId) => {
  const data = await api.get(`/oauth/check_authorization?client_id=${clientId}`);

  return data;
};

export const sendOAuthAuthorize = async (clientId) => {
  const data = await api.post('/oauth/authorize', {
    client_id: clientId,
  });

  return data;
};
