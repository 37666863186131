import Cookies from 'js-cookie';
import { getDomain } from '../utils/domain';

const TOKEN_KEY = '@mlabs-token';
const ACCOUNT_TYPE = '@mlabs-account-type';
const LANGUAGE_KEY = '@mlabs-language';
const DISMISS_BANNER = 'mlabs-dismiss-banner';
export const TRACKING_REGISTER = '@tracking-register';

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TRACKING_REGISTER);
};

export const setAccountType = (value) => localStorage.setItem(ACCOUNT_TYPE, value);

export const getAccountType = () => localStorage.getItem(ACCOUNT_TYPE);

export const removeAccountType = () => localStorage.removeItem(ACCOUNT_TYPE);

export const getLanguage = () => Cookies.get(LANGUAGE_KEY);

export const removeCookie = () => {
  Cookies.remove(DISMISS_BANNER, {
    domain: getDomain(),
  });
};
