import api from './api';
import { sendEventLog } from './eventLog';

import { getToken, login } from './auth';
import { getPartnerStore } from './oauth';
import { logError } from '../utils/logError';

export const loginUser = async (response) => {
  if (response.data?.token != '') {
    await login(response.data.token);
  }
};

export const redirectUser = async (response, creatingUser = false) => {
  loginUser(response);
  const token = await getToken();
  const meResponse = await api.get('/me');
  const account = meResponse.data;
  const event = creatingUser ? 'signup_click_start-button' : 'login';

  try {
    await sendEventLog(event, account.id, token, { origin: account.origin_key });
  } catch {
    logError('Error sending event');
  }

  const isRedirectRules = await redirectRules(account, creatingUser);

  if (!isRedirectRules) {
    window.location.assign(`${response.data.callback_url}/login/?token=${response.data.token}`);
  }
};

export const redirectRules = (account, creatingUser) => {
  const isPartner = JSON.parse(getPartnerStore());
  let isRedirect = true;

  if (isPartner) {
    window.location.assign(
      `/oauth/authorize?client_id=${isPartner.client_id}&callback_url=${isPartner.callback_url}`,
    );
  } else if (!account.timezone) {
    window.location.assign(`/accounts/settings${creatingUser === true ? '?new_user=true' : ''}`);
  } else {
    isRedirect = false;
  }

  return isRedirect;
};
