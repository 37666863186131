import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Container } from './styles';

export default function Loading({ opacity = false }) {
  return (
    <Container opacity={opacity}>
      <CircularProgress size="64px" />
    </Container>
  );
}
