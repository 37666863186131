import api from '../services/api';

const useCore = () => {
  const fetchPrivateFeature = async () => {
    let feature = {};

    try {
      const coreUrl = process.env.REACT_APP_CORE_API_URL.replace('/v1', '');
      feature = await api.get(`${coreUrl}/features`, { headers: { 'Accept-Version': 'v1' } });
    } catch (error) {
      console.error("Can't get feature");
    }

    return feature.data;
  };

  const fetchPublicFeature = async (keyFeature) => {
    let feature = {};

    try {
      feature = await api.get('/features/check', { params: { key: keyFeature } });
    } catch (error) {
      console.error('Feature not found');
    }
    return { data: [{ attributes: { key: keyFeature, ...feature.data } }] };
  };

  const fetchFeature = async (keyFeature, isPublic) => {
    const feature = isPublic ? await fetchPublicFeature(keyFeature) : await fetchPrivateFeature();

    return feature;
  };

  return {
    fetchFeature,
  };
};

export default useCore;
