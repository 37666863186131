import styled from "styled-components";

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: ${props => props.opacity ? 'rgba(255, 255, 255, 0.7)' : '#fff'};
`;

export { Container }
