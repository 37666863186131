import axios from 'axios';

import { getToken } from './auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_CORE_API_URL,
});

api.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default api;
