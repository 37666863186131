function normalizeLocale(language) {
  const locales = {
    pt: 'pt-BR',
    es: 'es',
    en: 'en',
  };

  return locales[language];
}

export function updateLang(event) {
  document.documentElement.setAttribute('lang', normalizeLocale(event.detail.language));
}
