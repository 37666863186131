import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import { getLanguage } from '../services/auth';

const isDevelopment = process.env.NODE_ENV === 'development';
const ML_TRANSLATOR_CHANGE_LANGUAGE_EVENT = 'ml-translator-on-change-language';

async function callback() {
  window.addEventListener(ML_TRANSLATOR_CHANGE_LANGUAGE_EVENT, (ev) => {
    if (!ev.detail) {
      return;
    }

    i18n.changeLanguage(ev.detail.language);
  });
}

export default i18n
  .use(Backend)
  .use(initReactI18next)
  .init(
    {
      lng: getLanguage() || 'pt-BR',
      fallbackLng: 'pt-BR',
      debug: isDevelopment,
      react: {
        useSuspense: false,
        wait: true,
      },
    },
    callback,
  );
