import React, { createContext, useState } from 'react';
import useCore from '../hooks/useCore';
import api from '../services/api';
import { login } from '../services/auth';

export const CoreApiContext = createContext();

export const CoreApiProvider = ({ children }) => {
  const [features, setFeatures] = useState({});
  const [currentAccount, setCurrentAccount] = useState({});

  const { fetchFeature } = useCore();

  const loadFeature = async (keyFeature, isPublic) => {
    const response = await fetchFeature(keyFeature, isPublic);
    let feature;

    setFeatures(() => {
      response?.data?.forEach((item) => {
        const { attributes } = item;

        features[attributes.key] = attributes;
        if (attributes.key === keyFeature) feature = attributes;
      });

      return { ...features };
    });

    return feature;
  };

  const getFeatureToggle = async (featureName, setFeatureState, isPublic = true) => {
    if (features[featureName] && features[featureName]?.active) {
      setFeatureState(features[featureName]?.active);
    } else {
      const featureResponse = await loadFeature(featureName, isPublic);
      setFeatureState(featureResponse?.active);
    }
  };

  const getMe = async () => {
    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.get('access_token')) {
      login(queryParams.get('access_token'));
    }

    const response = await api.get('/me');

    setCurrentAccount(response?.data || {});

    return response;
  };

  return (
    <CoreApiContext.Provider value={{ currentAccount, getMe, getFeatureToggle }}>
      {children}
    </CoreApiContext.Provider>
  );
};
