export default {
  hoverOpacity: 0.92,
  default: {
    white: '#fff',
    lightGrey: '#dbdbdb',
    black87: 'rgba(0, 0, 0, .87)', // aka #666666
    black6: 'rgba(0, 0, 0, .6)',
    black05: 'rgba(0, 0, 0, .05)',
    gray: '#474747',
    light: '#7c7c7c',
  },
  primary: {
    light: '#fff3e0',
    main: '#f26526',
    dark: '#e65100',
    extraDark: '#a83b0a',
  },
  secondary: {
    light: '#e3f2ff',
    main: '#1a73e8',
    dark: '#1f41b6',
  },
  notification: {
    warning: '#ffd04d',
  },
  gray: { 50: '#FAFAFA', 100: '#E0E0E0', 500: '#7A7A7A', 700: '#474747' },
  green: {
    500: '#46b43c',
  },
};
