import axios from 'axios';

const logURL = process.env.REACT_APP_EVENT_LOG_API_URL;

export const sendEventLog = async (event, account_id, token, aditional_data) => {
  if (!logURL) return;

  let log_data = {
    event,
    account_id,
    token,
    date: new Date(),
    ...aditional_data,
  };

  await axios.post(logURL, log_data);
};
