import * as React from 'react';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import '@engenharia/ds-core/dist/main.css';

import theme from './styles/theme';
import { CoreApiProvider } from './contexts/coreApiContext';

export const Providers: React.FC = ({ children }) => (
  <StylesProvider injectFirst>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CoreApiProvider>{children}</CoreApiProvider>
      </BrowserRouter>
    </ThemeProvider>
  </StylesProvider>
);
