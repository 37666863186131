import React from 'react';
import ReactDOM from 'react-dom';

import './config/setupFirebase';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import '@engenharia/ml-translator';

import NewRelic from './config/newRelic';
import App from './App';

import { initAmplitude } from './services/amplitude';

const newRelicLicenseKey = process.env.REACT_APP_NEW_RELIC_LICENSE_KEY;
const newRelicApplicationID = process.env.REACT_APP_NEW_RELIC_APPLICATION_ID;
const newRelicAccountID = process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID;
const bugsnagKey = process.env.REACT_APP_BUGSNAG_KEY;

initAmplitude();

Bugsnag.start({
  apiKey: bugsnagKey!,
  plugins: [new BugsnagPluginReact()],
  onError: (event: any) => {
    if (
      event.originalError.response.status === 401 &&
      event.originalError.response.config.url.includes('/me')
    ) {
      return false;
    }

    if (
      event.originalError.response.status === 400 &&
      event.originalError.response.config.url.includes('/features')
    ) {
      return false;
    }

    return true;
  },
});

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
    <NewRelic
      licenseKey={newRelicLicenseKey}
      applicationID={newRelicApplicationID}
      accountID={newRelicAccountID}
    />
  </React.StrictMode>,
  document.getElementById('root'),
);
