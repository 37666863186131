import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ${reset}

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  
  html {
    overflow-y: auto;
  }

  body {
    background-color: #fafafa;
  }
`;

export default GlobalStyle;
