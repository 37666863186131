import { fade, createMuiTheme } from '@material-ui/core/styles';
import colors from './colors';
import spaces from './spaces';

const theme = createMuiTheme({
  spacing: 4,
  overrides: {
    MuiLink: {
      root: {
        fontFamily: ['"Poppins"', 'sans-serif'].join(','),
      },
      underlineHover: {
        textDecoration: 'underline',
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: spaces.xs,
      },
      marginNormal: {
        marginBottom: '0px',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    MuiOutlinedInput: {
      root: {
        fontSize: '16px',
      },
      notchedOutline: {
        ['& legend']: {
          fontSize: '13px'
        }
      }
    },
    MuiSnackbarContent: {
      root: {
        maxWidth: '400px',
        color: colors.default.black87,
        backgroundColor: colors.notification.warning,
        boxShadow: 'none',
        textAlign: 'justify',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiExpansionPanel: {
      root: {
        '&:first-child': {
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        },
        '&:last-child': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        '&:before': {
          opacity: '1 !important',
        },
        '&$expanded': {
          margin: '0',
          paddingBottom: spaces.small,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
      },
      expanded: {},
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: '0',
      },
      content: {
        margin: `${spaces.small} 0`,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: '0',
      },
    },
    MuiAccordionDetails: {
      root: {
        borderTop: `1px solid ${colors.default.lightGrey}`,
        padding: `${spaces.small} 0`,
      },
    },
    MuiStepper: {
      root: {
        padding: spaces.xs,
        margin: '0 auto',
        width: '100%',
      },
    },
    MuiStepIcon: {
      text: {
        fill: colors.default.white,
      },
    },
  },
  palette: {
    primary: {
      light: colors.primary.light,
      main: colors.primary.main,
      dark: colors.primary.dark,
    },
    secondary: {
      light: colors.secondary.light,
      main: colors.secondary.main,
      dark: colors.secondary.dark,
    },
    error: {
      main: '#D82418',
    }
  },
  typography: {
    h1: {
      fontSize: '48px',
      lineHeight: '62.4px',
      letterSpacing: '-0.78px',
      color: colors.default.black87,
    },
    h2: {
      fontSize: '40px',
      lineHeight: '52px',
      letterSpacing: '-0.34px',
      color: colors.default.black87,
    },
    h3: {
      fontSize: '32px',
      lineHeight: '41.6px',
      color: colors.default.black87,
    },
    h4: {
      fontSize: '24px',
      lineHeight: '36px',
      letterSpacing: '0.18px',
      color: colors.default.black87,
    },
    h5: {
      fontSize: '16px',
      lineHeight: '24px',
      color: colors.default.black87,
    },
    h6: {
      fontSize: '14px',
      lineHeight: '21px',
      letterSpacing: '0.18px',
      color: colors.default.black87,
    },
    body1: {
      fontSize: '18px',
      lineHeight: '27px',
      letterSpacing: '0.58px',
      color: colors.default.black6,
    },
    body2: {
      fontSize: '14px',
      lineHeight: '21px',
      letterSpacing: '0.26px',
      color: colors.default.black6,
    },
    subtitle1: {
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.16px',
      color: colors.default.black6,
    },
    subtitle2: {
      fontSize: '14px',
      lineHeight: '21px',
      letterSpacing: '0.1px',
      color: colors.default.black6,
    },
    caption: {
      fontSize: '12px',
      lineHeight: '18px',
      letterSpacing: '0.42px',
      color: colors.default.black6,
    },
    overline: {
      fontSize: '12px',
      lineHeight: '18px',
      letterSpacing: '2.09px',
      color: colors.default.black6,
    },
    button: {
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '1.3px',
      textTransform: 'none',
    },
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    fontWeightRegular: 400,
  },
});

export default theme;
