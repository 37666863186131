import React, { useEffect } from 'react';

import './config/i18n';

import Routes from './routes';
import { initGtm } from './services/gtm';
import GlobalStyle from './styles/GlobalStyle';
import { Providers } from './Providers';
import { updateLang } from './services/lang';

const MLABS_TRANSLATOR_ON_CHANGE_LANGUAGE = 'ml-translator-on-change-language';
const MLABS_TRANSLATOR_ON_INIT = 'ml-translator-on-init';

initGtm();

window.addEventListener(MLABS_TRANSLATOR_ON_INIT, updateLang);

window.addEventListener(MLABS_TRANSLATOR_ON_CHANGE_LANGUAGE, updateLang);

export default function App() {
  return (
    <Providers>
      <GlobalStyle />
      <Routes />
    </Providers>
  );
}
