import TagManager from 'react-gtm-module';

const gtmId = process.env.REACT_APP_GTM_ID;

export const initGtm = () => {
  if (!gtmId) {
    return;
  }

  TagManager.initialize({
    gtmId: gtmId,
  });
};

export const setDataLayer = (value) => {
  const dataLayer = window.dataLayer || [];

  dataLayer.push(value);
};
