export const getDomain = () => {
  let hostname = window.location.hostname.split('.')?.pop();

  const domain = {
    localhost: 'localhost',
    br: '.mlabs.com.br',
    io: '.mlabs.io',
  };

  return domain[hostname];
};
